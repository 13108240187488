import Typed from 'typed.js';
import React from 'react';
import { color } from "styled-system";
import styled from "styled-components";

const Text = styled.span`
  white-space: 'pre';
  ${color};`

const TypedText = ({texts}) => {
	// Create reference to store the DOM element containing the animation
	const el = React.useRef(null);
  // Create reference to store the Typed instance itself
	const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
    	strings: texts,
      typeSpeed: 50,
      backSpeed: 50,
      loop: true
    };
    
    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);
    
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    }
  }, [])

  return (
        <Text color="primary" ref={el} />
  );
}

export default TypedText;