import React, { useContext }  from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { Title, Button, Section, Box, Text } from "../../components/Core";
import TypedText from "../../components/TypedText";
import imgL from "../../assets/image/jpg/valeria.jpg";

const ImgRight = styled.img`
  max-width: 100%;
  border-radius: 2rem;
  box-shadow: 0 20px 60px 0 rgb(244 151 190 / 80%);
`;

const Hero = () => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" className="mb-5 mb-lg-0">
              <Box>
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                  Копирайтер
                </Text>
                <Title variant="hero">
                  Текстовая поддержка<br/>
                  <TypedText texts={["инстаграма", "сайта" , "блога", "запуска"]}></TypedText>
                </Title>

                <Box mt="52px">
                  
                    <Button arrowRight 
                    onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleContact();
                    }}>Нужен текст</Button>
                  
                </Box>
              </Box>
            </Col>
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={imgL} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
