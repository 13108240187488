
import imgT33 from "../assets/image/jpg/photo/masonry/texts/33.jpg";
import imgT32 from "../assets/image/jpg/photo/masonry/texts/32.jpg";
import imgT31 from "../assets/image/jpg/photo/masonry/texts/31.jpg";
import imgT30 from "../assets/image/jpg/photo/masonry/texts/30.jpg";
import imgT29 from "../assets/image/jpg/photo/masonry/texts/29.jpg";
import imgT28 from "../assets/image/jpg/photo/masonry/texts/28.jpg";
import imgT27 from "../assets/image/jpg/photo/masonry/texts/27.jpg";
import imgT26 from "../assets/image/jpg/photo/masonry/texts/26.jpg";
import imgT25 from "../assets/image/jpg/photo/masonry/texts/25.jpg";
import imgT24 from "../assets/image/jpg/photo/masonry/texts/24.jpg";
import imgT23 from "../assets/image/jpg/photo/masonry/texts/23.jpg";
import imgT22 from "../assets/image/jpg/photo/masonry/texts/22.jpg";
import imgT21 from "../assets/image/jpg/photo/masonry/texts/21.jpg";

import imgT20 from "../assets/image/jpg/photo/masonry/texts/20.jpg";
import imgT19 from "../assets/image/jpg/photo/masonry/texts/19.jpg";
import imgT18 from "../assets/image/jpg/photo/masonry/texts/18.jpg";
import imgT17 from "../assets/image/jpg/photo/masonry/texts/17.jpg";
import imgT16 from "../assets/image/jpg/photo/masonry/texts/16.jpg";
import imgT15 from "../assets/image/jpg/photo/masonry/texts/15.jpg";
import imgT14 from "../assets/image/jpg/photo/masonry/texts/14.jpg";
import imgT13 from "../assets/image/jpg/photo/masonry/texts/13.jpg";
import imgT12 from "../assets/image/jpg/photo/masonry/texts/12.jpg";

import imgT11 from "../assets/image/jpg/photo/masonry/texts/11.jpg";
import imgT10 from "../assets/image/jpg/photo/masonry/texts/10.jpg";

import imgT9 from "../assets/image/jpg/photo/masonry/texts/9.jpg";
import imgT8 from "../assets/image/jpg/photo/masonry/texts/8.jpg";
import imgT7 from "../assets/image/jpg/photo/masonry/texts/7.jpg";

import imgT6 from "../assets/image/jpg/photo/masonry/texts/6.jpg";
import imgT5 from "../assets/image/jpg/photo/masonry/texts/5.jpg";
import imgT4 from "../assets/image/jpg/photo/masonry/texts/4.jpg";

import imgT3 from "../assets/image/jpg/photo/masonry/texts/3.jpg";
import imgT2 from "../assets/image/jpg/photo/masonry/texts/2.jpg";
import imgT1 from "../assets/image/jpg/photo/masonry/texts/1.jpg";

export const works2 = [
  {
    categories: [`reader`],
    thumbnail: imgT1,
  },

  {
    categories: [`client`],
    thumbnail: imgT2,
  },
  {
    categories: [`client`],
    thumbnail: imgT3,
  },

  {
    categories: [`client`],
    thumbnail: imgT4,
  },{
    categories: [`client`],
    thumbnail: imgT5,
  },

  {
    categories: [`client`],
    thumbnail: imgT6,
  },{
    categories: [`reader`],
    thumbnail: imgT7,
  },

  {
    categories: [`reader`],
    thumbnail: imgT8,
  },{
    categories: [`reader`],
    thumbnail: imgT9,
  },

  {
    categories: [`reader`],
    thumbnail: imgT10,
  },{
    categories: [`reader`],
    thumbnail: imgT11,
  },

  {
    categories: [`client`],
    thumbnail: imgT12,
  },{
    categories: [`client`],
    thumbnail: imgT13,
  },

  {
    categories: [`client`],
    thumbnail: imgT14,
  },{
    categories: [`client`],
    thumbnail: imgT15,
  },

  {
    categories: [`client`],
    thumbnail: imgT16,
  },


  {
    categories: [`client`],
    thumbnail: imgT17,
  },

  {
    categories: [`reader`],
    thumbnail: imgT18,
  },

  {
    categories: [`reader`],
    thumbnail: imgT19,
  },

  {
    categories: [`reader`],
    thumbnail: imgT20,
  },
  {
    categories: [`reader`],
    thumbnail: imgT21,
  },

  {
    categories: [`reader`],
    thumbnail: imgT22,
  },{
    categories: [`reader`],
    thumbnail: imgT23,
  },

  {
    categories: [`reader`],
    thumbnail: imgT24,
  },{
    categories: [`client`],
    thumbnail: imgT25,
  },

  {
    categories: [`client`],
    thumbnail: imgT26,
  },


  {
    categories: [`client`],
    thumbnail: imgT27,
  },

  {
    categories: [`reader`],
    thumbnail: imgT28,
  },

  {
    categories: [`reader`],
    thumbnail: imgT29,
  },

  {
    categories: [`reader`],
    thumbnail: imgT30,
  },
  {
    categories: [`reader`],
    thumbnail: imgT31,
  },

  {
    categories: [`client`],
    thumbnail: imgT32,
  },{
    categories: [`client`],
    thumbnail: imgT33,
  }

];
