import React from "react";
import { Element } from "react-scroll";
import { rgba } from "polished";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import Works from "../sections/masonry-3-column/Works";
import Contact from "../sections/landing1/Contact";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text } from "../components/Core";
import Testimonials from "../sections/others/Testimonials";
import styled from "styled-components";


const TileItem = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 1)};
  margin-top: 2rem;
  height: 8rem;
`;

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <Section className="pt-4 pb-0" bg="dark">
          <Container color="lightShade">
            <Title variant="secLg" className="mb-5 mt-5 pb-lg-4 text-center" color="light">
              Напишу для вас текст,<br />контентный или продающий.<br />
              Чтобы вы:
            </Title>
            <Row>

              <Col lg="6" className="mb-5 pr-lg-5" >

                <Title variant="cardLg" className="mb-4 pl-4" color="light">
                  <i class="icon icon-check-simple" style={{ position: 'absolute', left: '0px' }}></i>
                  Чувствовали доверие и искренний интерес аудитории
                </Title>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5" >

                <Title variant="cardLg" className="mb-4 pl-4" color="light">
                  <i class="icon icon-check-simple" style={{ position: 'absolute', left: '0px' }}></i>
                  Получали больше клиентов, которым не терпится оплатить
                </Title>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5" >

                <Title variant="cardLg" className="mb-4 pl-4" color="light">
                  <i class="icon icon-check-simple" style={{ position: 'absolute', left: '0px' }}></i>
                  Разгрузили себя и нашли время для книги или спокойного сна
                </Title>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5" >

                <Title variant="cardLg" className="mb-4 pl-4" color="light">
                  <i class="icon icon-check-simple" style={{ position: 'absolute', left: '0px' }}></i>
                  Занимались другими задачами бизнеса и ощущали спокойствие за тексты
                </Title>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5" >

                <Title variant="cardLg" className="mb-4 pl-4" color="light">
                  <i class="icon icon-check-simple" style={{ position: 'absolute', left: '0px' }}></i>
                  С гордостью листали свой блог, который читают, лайкают и комментируют
                </Title>
              </Col>


            </Row>
          </Container>
        </Section>

        <Element name="works" id="portfolio">
          <Works title="Портфолио" showCatgeories={false} />
        </Element>

        <Element name="works" id="why-me">
        <Section className="pt-4 pb-8" >
          <Container >
            <Title variant="secLg" className="mt-5 pb-lg-4 text-center" >
              Почему я:
            </Title>
            <Row>
              <Col lg="4"  >
                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                    Как Эркюль Пуаро отыскиваю то, чего жаждет ваша целевая аудитория
                  </Text>
                </TileItem>
              </Col>
              <Col lg="4" >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                  С изяществом пантеры подвожу к покупке вашего продукта или услуги
                  </Text>
                </TileItem>
              </Col>
              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                  Зову Маслоу, Грейвза, Бартла и Шерингтона для глубокого и многогранного анализа вашей Ц/А
                  
                  </Text>
                </TileItem>
              </Col>

              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light" >
                  Пишу в унисон с вашим стилем, голосом и тоном
                  </Text>
                </TileItem>
              </Col>

              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                  С творчеством подхожу к каждому тексту
                  </Text>
                </TileItem>
              </Col>
              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                    Избавляю тексты от воды, шаблонов и штампов
                  </Text>
                </TileItem>
              </Col>

              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                    Дружу с грамотностью и дедлайнами
                  </Text>
                </TileItem>
              </Col>

              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                    По вашему желанию добавляю в тексты юмор
                  </Text>
                </TileItem>
              </Col>
              <Col lg="4"  >

                <TileItem>
                  <Text variant="tag" className="p-4" color="light">
                    Не ворочу нос от голосовух
                  </Text>
                </TileItem>
              </Col>
            </Row>
          </Container>
        </Section>
        </Element>


        <Element name="testimonials" id="testimonials">
        <Title variant="secLg" className="mt-3 text-center" >
        Отзывы
            </Title>
          <Testimonials />
        </Element>

        <Contact />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
