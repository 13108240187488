import imgT1 from "../assets/image/jpg/masonry-image-1.jpg";
import imgT2 from "../assets/image/jpg/masonry-image-2.jpg";
import imgT3 from "../assets/image/jpg/masonry-image-3.jpg";
import imgT4 from "../assets/image/jpg/masonry-image-4.jpg";
import imgT5 from "../assets/image/jpg/masonry-image-5.jpg";
import imgT6 from "../assets/image/jpg/masonry-image-6.jpg";
import imgT7 from "../assets/image/jpg/masonry-image-7.jpg";
import imgT8 from "../assets/image/jpg/masonry-image-8.jpg";

export const masonryWorks1 = [
  {
    brand: `Контентные посты для Инстаграм`,
    slug: `perfumed-app`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`branding`],
    thumbnail: imgT1,
  link : "https://drive.google.com/drive/u/0/folders/1xH5rXRAQ0C8fi13PhzANDijoQtXPLagI"
},
  {
    brand: `Продающие и таргет-посты для Инстаграм`,
    slug: `travel-app`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`photography`],
    thumbnail: imgT2,
  link : "https://drive.google.com/drive/folders/15LvbaGRnu9lDTInwoGBJgshVsVOfadat"
},
  {
    brand: `Прототип лендинга`,
    slug: `shopper`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`branding`, `ux-design`],
    thumbnail: imgT3,
  link : "https://drive.google.com/drive/folders/1-_S2GPLmRXtRXexWm9t6ouJiY61MzWjr?usp=sharing"
},
  
  {
    brand: `Статьи`,
    slug: `travel-app`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`photography`, `ux-design`],
    thumbnail: imgT5,
  link : "https://drive.google.com/drive/folders/1bgtKN_3rk-Y28PJGQcnwxaxETwDgjXjP?usp=sharing"
},
{
  brand: `Текст для чат-бота`,
  slug: `candy-store`,
  title: `Adaptable but Identifiable Product for Kandinsky.`,
  categories: [`photography`, `ux-design`, `branding`],
  thumbnail: imgT4,
link : "https://drive.google.com/drive/folders/1CDphVGaRb5Ok0v7oRRauYB1VMp-__78A?usp=sharing"
},
// {
//   brand: `Контент-план`,
//   slug: `travel-app`,
//   title: `Adaptable but Identifiable Product for Kandinsky.`,
//   categories: [`photography`, `ux-design`],
//   thumbnail: imgT6,
// link : "https://drive.google.com/drive/folders/1vETZUa8ruVyOSkPovwBKizTYZ6CaONxf?usp=sharing"
// },
  {
    brand: `Прототип страницы с лид-магнитом`,
    slug: `app-ice-cream`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`ux-design`, `branding`],
    thumbnail: imgT7,
  link : "https://docs.google.com/presentation/d/1pG5fIY7WMAZR7OsZ-VbiIsGl3nyOoV3MZLIDWh-9ozc/edit#slide=id.p"
},
  {
    brand: `Рассказ «Искусство бесконечно загнивать»`,
    slug: `Camera`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: [`photography`, `ux-design`],
    thumbnail: imgT8,
  link : "https://cutt.ly/N9fziVw"
}
];
