import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import GlobalContext from "../../context/GlobalContext";
import { Section, Title, ButtonIcon, Text } from "../../components/Core";
import Availability from "../../components/Availability";
import { FaSmileWink } from "react-icons/fa";
import { color, space, typography, shadow } from "styled-system";

const ColorWrapper = styled.span`
${color};
`

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <Section>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="7">
              {/* <Availability /> */}
              <div className="text-center my-5">
                <Title>Ну что, напишем?<ColorWrapper color="success"><FaSmileWink /></ColorWrapper></Title>
              </div>
              <div className="text-center">
                <ButtonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Напишем!
                </ButtonIcon>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
